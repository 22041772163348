import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
export const _frontmatter = {
  "key": 6,
  "title": "15 Minutes Late",
  "client": null,
  "type": "audiovisual",
  "date": "2019",
  "description": "After failing to talk to gay emancipation filmmaker Rob de Vries due to him dying ten minutes earlier, Richard tries to get to know him via the people around him. Richard is a filmmaker who didn’t know for a while how to handle his creativity in combination with compliance with societal expectation and compares. He want to compare himself to Rob de Vries and why he did not care about this compliance.",
  "image": "../../static/img/work/15-minutes-late/4.png",
  "_video": "_https://www.youtube.com/embed/YDWfud6-X-s",
  "inProgress": true,
  "alt": "film still",
  "runningTime": "10:30",
  "rating": 3,
  "draft": true
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Gallery width={50} mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1920px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0da7e8feb595b2e2957fc38a087d85f4/a987b/1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACqklEQVQozwGfAmD9AGmBjm2EkW+IlXKNmXKMmHKMmHiTn3qXoX+bp32aqI6ltWJwf1hjcHB7hGdudmpzfmt2hEZSYYKTpoKZqwBqgY5shJFviJVwipVwjJV0j5l2kZx7mKKBnamGoa6ht8dygo5GTFReY21scntFS1ZvfI5daoCMn7ORp7gAaYCMa4OPbYeSb4iSco2WdI+aeJSefZmkgZ6phKCrnLTDbnmCFBQZGxwhVlpqQUtukaG6e4yeiZ6xhqCwAGiAjWuDkG6HknCJlG2HkGR6hHOOl3uZon+cp4GeqY2otlFcZwUHCxIWHDtAYUdQgz5HbHOCmImgsnyXpQBmfolpgY1viJRwi5Vpgotgc39thpB8m6R+naeFp692k54TGCMHCg8hKDcSFiccITwPEiQkLDx+mKl/naoAZ3+KaoONa4WPcIyXc4mWZHmGdJCafZylfJ6liaq1UWFwAAAGDBAZJSw5BAUHEBMlND5TLjhGWm99hqeyAGyFkG+Ik2+Jk2+Jk3CJlGd+iniWoHubpHCLmYKZrjZBTgAAAC81QISQn1RdZQAADE1bc32WqlhrfWuBlABpgoxth5Jxi5VuiZJxjZZ2kp1thZJaa313i52Bnq4jLjYICQ5KUVdbYmt5hZJia3ZbaoB3kq1sh5pbbIIAZ4GMa4WQbomUco6XbIWRVWR1VFxvZXiIf52pm7PGPUdTCAoPExYaBggLJiovW2RuYW19nrTLfpWraYKVAGqCj2+Ilm2GklRndjM8TT9HV2l+inGNnHiRpKK70GNygwABBgsNEQgMDwYJDBEVGUNJUtvj76i70Y6jtwBnf4xwiphNXm4kKz8nLDhhdIB0kZ56kad4kqiEoLZ8jqgLDhYBAgUICQ0ICg4MEBQtMjuqutObtM6/z9/MWiCNKhktWAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/0da7e8feb595b2e2957fc38a087d85f4/3cea3/1.webp 1000w", "/static/0da7e8feb595b2e2957fc38a087d85f4/34ef4/1.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/0da7e8feb595b2e2957fc38a087d85f4/e11df/1.png 1000w", "/static/0da7e8feb595b2e2957fc38a087d85f4/a987b/1.png 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0da7e8feb595b2e2957fc38a087d85f4/a987b/1.png",
                "alt": "Richard traveling to Rob de Vries",
                "title": "Richard traveling to Rob de Vries",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1920px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2613c510c3e7056f1ca7b65b125c44a6/a987b/2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACDklEQVQoz13Ry2oTUQDG8UlimyLYFprMZJq002TOmXOZ+5lzmowzk6SdTJI20otEQwNCqboQBS+gSMGVT+BCfIPiwo0LL7jS5/BlrN1NH+DHn49PkgoFSZJKsrzsOWVqSFhTGD4chKGwl1zg9HjVazW2YTMyjMQgXYOmeLWxekkK/+EVLlZlidA1k7zIep/63Z/3pr9Pz/xeGO6GGockol7PErtWmFrR2FE213K4pNQk00Ke964Tv4QsDsSTxw9nJ9Ow38ZtwrtuknrpMBiP2ew4bDaVPK6pC45fRBgQWqdUMprLLjp/8/T1szMqaLzDBlkwGYuDPT4/jkFLzeEbtfUFl5UILZqkQGCJgIZwpvOj8Z2h07GSHX+Y8clIHIz4yVEEwXVcv8SLxNxkyRKlJQILGEi6VrGQtW1HfZYOgtGQ7w/5/cMIG/Vrm9Wi5VRt4bazBYrKGI5M+xF12zqCuu46JO6xUSb2M344CRHI46JcW/HaFk+p6EsUqhD9CrtfXP5qiz4A5FQ3Mgi8tunFzk7KEWjk8KLSqLHEEQOVdWSMNlrog4h+BMl7aH/l4kKwC999bpINj8oC6Hq+XFbqFf82CLqKv72CkQ6wD8k3N7kL8KwFPzL2d9j7zry5iWlsV+rXfparNx1XsYKK7RaItgK1erN5rptQ18pb6lsD/RHis2efAm3d31qq3pKu9D+kU2geRkvLCAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/2613c510c3e7056f1ca7b65b125c44a6/3cea3/2.webp 1000w", "/static/2613c510c3e7056f1ca7b65b125c44a6/34ef4/2.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/2613c510c3e7056f1ca7b65b125c44a6/e11df/2.png 1000w", "/static/2613c510c3e7056f1ca7b65b125c44a6/a987b/2.png 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/2613c510c3e7056f1ca7b65b125c44a6/a987b/2.png",
                "alt": "Image by Rob de Vries",
                "title": "Image by Rob de Vries",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1920px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e020f2b082993508b6de446af120a98a/a987b/3.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACqklEQVQozwGfAmD9AI+s0Ju52Je11YGUr3NnaZB8aqCLc5icpJSvzp7A0IeXnHx6c3yDjY6qyd/855eywoOawnuJqGhugXyVuACRr9Kcu9qXttJZUmRmUE99ZFuBWVCahXObs8yqzs+kuaiQj32an42bq7qwzNaPrciFnsSDkrRrb4J3i7AAk7HUnLvblrPNTDpGVy81Yzc7az4/eFVJhH6OrcXUsL+tnqCFqrGUjpGlfIm1dJLAiqTHh5i7cXSHd4alAJSy1Jq52J251EY5SkQlK1AqMU0wNFAyNHJaYpipvpyts5KVgpqcgYCVrW+PwGyLuYObv4aWtXF0gnJ6kQCWtdWZudiUrMRFPEs5IClAJS1JKjFhPjlpS0pwY2GCgoSFhHyLi3pgboJIZpJXc6J5j7WHmbpscYZobIIAmLjYkK3OV1VhOigySSstUisuWjMzakM6TywxY1NNaFpRalxXhHpsaXKCUWiUaIGsfZS6hpq7ZGqFWllxAJi32IylxVJQVDgrLz4hJUwqKls1MV86M00cI1RDQGRZUmJUTm1eUXx5eHOLsXiYwoCdv3yNsV5mh01MagCRrs9xgZpNSUhPR0A8Lis2Gx5KJiZVKyxnIiNVPzpqYFRqW1JoW1BjVkl5dHF6kLR2krxue55YX4BIR2YAVF98SUVLSUQ8UktAUEo+Rz41UTYwZTMyYxUXYjQuZl1RaVtPb2FSWk9GYFNGf3pxZnCPUFuESVJ2g5u6AHqKo0pDQ01HP01FPElBOExFOk1HO1BANlwoImYjH2dXS2ZYTHFkUnBiUUg+OltOQ21iWUdGYjM3X4qpyQB4h6NJQz9ORz1RSD9RSD1KQTdORjhRRzpHQzdVMipnSUJpXU9pW01+blpHPzY+NDVhVEhkW14qKk11krXBayIaDdEjnQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/e020f2b082993508b6de446af120a98a/3cea3/3.webp 1000w", "/static/e020f2b082993508b6de446af120a98a/34ef4/3.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/e020f2b082993508b6de446af120a98a/e11df/3.png 1000w", "/static/e020f2b082993508b6de446af120a98a/a987b/3.png 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/e020f2b082993508b6de446af120a98a/a987b/3.png",
                "alt": "Interview with Salamon",
                "title": "Interview with Salamon",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1920px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/41ef761fa0bb95709292f22340759e90/a987b/4.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACjklEQVQozx3P20tTcQDA8YN5Lr/LuWzn7GxnOze3nZ3NTUeSLbR0mjSszG6gMTOzArMLmGYYkYbOJeIl0iwfLBJUUuxCkD3U0+gmrMDsoaf+EING79+Hz5fob65vKgsmy2PZzNTa+zerb1+sbyyNPhjuaE9Pzz/ZyH3Ib//Y+buz/HxxoHegv/NChUcwXaLqU9ta08RgS6qlwqqLR0fuTTxeW5ldnl14OXf6eMrLO/qGBhderebyn75++bxvT6Wm6qdq9lebnqDs9LiVhrpGYvpK+nzCqo5Yw0Pji+urY7OZyWcT13q6qiKxM+n05NO5ze3N1ZUl0+kMBay+S5drA15bdlhW6c3eu8TD6+0dCUt3urKZmd9/fs0/yozP3D984mgyvrtc03puD7z++O7n1tbZI8fiht7V2nbAVMKyILs8ydpDxNTV9s6EFVHUpobm7OjInb4bjamDJaoe1QyrYGtIZcey3/P5W909NseXUthGbFjiFEWTRDfRfbK5Phwy3D5D4EOSOxaI6B4tGrQT0bIKv2mKUm0y+S2Xi+tWiGZtijVIaAqsrvldokxUBkNVVkgTJcUpFVJNcIicI1wS2mvbEY/sFXhNNy+2nLMZIUBhlYJaMZARdPK8g2WJiE+rDEZ8ggMCyEPMQeQWXTHD8BUgiixxLI+wH3JREvso4CWBtosRGQZDIDCQsFQTIw4wAEDEIVYHrF9WE0E7JLsCooOH0Ms7yyXRQEgrZjSSkUlKAEDAWEcC4RUEBCCHEEZsGc3WFYEYiW3OLSCeoRiGZjCAHoBUErgowNE0ommexTLPGw6RkDBGENNM4YSroViTg36AfMgJwH8OzdA0zdHAKKIVBnKFjqRUlyRiDCnqH24EkEU9cW1CAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/41ef761fa0bb95709292f22340759e90/3cea3/4.webp 1000w", "/static/41ef761fa0bb95709292f22340759e90/34ef4/4.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/41ef761fa0bb95709292f22340759e90/e11df/4.png 1000w", "/static/41ef761fa0bb95709292f22340759e90/a987b/4.png 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/41ef761fa0bb95709292f22340759e90/a987b/4.png",
                "alt": "Members Pink Salon who do not want to show their face",
                "title": "Members Pink Salon who do not want to show their face",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1920px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/41f7bf2b46657928c63eaa18ee834474/a987b/5.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACT0lEQVQoz2NgZGRkYGAQFBKRlFMUEJOUUZKTV5CWl5QRE5cQkZKSUJKTVpZT0lCUkZcUlhWTVJYWVhRn52VngACIZn5BIVk5JT4RCXVNdWtDXSVZOTkpaRlZeVFZWTkVBVE5oHES0uLCPBICfDJC7LycDDAA0szOw28iq2QiKRcd5hufEq2roSYjKS0iJqWoqqJrpCurpmKtpWivLichxM8pzsfCzYqimZOb30JGOdTbLcbfzdXSXE1FTVRMUtvIwM3Z2sJU39hYz0FNzkVHqa0qpKAmgomDFaoP4mwlGfmU+OC02EA/K4swBxMbY203V6vMJO9od30bA7W8ZM8gY1UTRcl1C7PnziqQkpOA+heiWVpGJdLZ2VVJWUFWJSnJMznJIzXRLTPMxt/WMMjLpqUkxNJcQ1BGrNjLNMBSV0RCGEWzgpyqg6quiJAkh6CkvpFmUohrTZl7XJi5GZtwoLi0i56mkLSkjLyEs66ykZYKFz831MdMTExA0s7eLDHEOzzMqzg/0svKOD3SY9HUhKJiv9ycgJ62WBt7IxYBPlEpYWExIVF5MRYeNqhmZmaQ5qg4t96syC17urYuLU1zMFm+LOvD1+lHzrfPWFK093Czi5cRm6CAjoKEp7qUvJQAE5rm1DSvloTg/r7CC/dn79ndvH1r9Z5DLZPmVC5Y1bpweY2InDiXiFB4kMmkeg8/b0MuQS5oaEOcbWGqm54Usf/SxAeflx4409VQF7d8W+2R29NP3Zx+6vpUa3s9CSmxTauSL53JWjY/nE+YFxJgAIyiirbO5QxHAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/41f7bf2b46657928c63eaa18ee834474/3cea3/5.webp 1000w", "/static/41f7bf2b46657928c63eaa18ee834474/34ef4/5.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/41f7bf2b46657928c63eaa18ee834474/e11df/5.png 1000w", "/static/41f7bf2b46657928c63eaa18ee834474/a987b/5.png 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/41f7bf2b46657928c63eaa18ee834474/a987b/5.png",
                "alt": "Image of Rob de Vries",
                "title": "Image of Rob de Vries",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1920px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1c6636d6ee225d292416ecb6b20727ec/a987b/6.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACn0lEQVQozw3QV08aAQAA4HtrE9MH26c+NE1tY1NN2mqqicEFGAQZcshGOGSlFRA82YYh04ODExAQAVuLUlFGj3GyNOk/q//gywd4TQ6r/EAvMtAZEtqG0KA9kEB6UKoP7rk68VziCEsgSc++125y164aN+VaGjuLRFLfzXaZzgLQmRBbBHOkVjpHv8rYYQlMO7seHah1iIxGjQ05zvoOEYgtZawJUrnqxcVNIl5EczV3pOALYsAiVSqQWcQaH5NnYnD11E0VmypXTvJts0b+ihIUaoRCFXdDNvF+yWHzZBMJnzsVQm+d3jOVHAG4oJayzFul8JbXtrgiLZMnps4zpAss9axEy1L6IwEkc8KmCcaevYHU5vJtA8U6SKyLoG1/sAkwpepVULLI4tH5YrFcYTAY7bCV/IlEGv/mcwV/1qpWt3dmeuH589frdKjyp3pTqfxttIl2f9gbARyhSCmXa3eUhzZHNBzNZIv5QtkpZ6lWpo4d9nKpbtDZpj+vvRz/EPCn6k3i+rp5V2138PsBMQJCLid65E+iyXSqkMuXzwvl8/OrmEX9gz3Ho1FYTIFw2/luauPV+AQWv+ziRBfv3reIx97oofcAxEIJNIwhkdMkdpbPlvKnBbstBG+DGg5leZ70dYYsVHgmp8gvxt7Ce8G7auPfcPRI9B/vR3itCzzVY/F8LJpPnpQSJ6VAKG3aD+jA9c2lORadS6aK1Cp4ja76+IVjPfDi9c6AGA6J4aAz6NY7QCScOo1nUCSDxErRWNF+GDfDYRMkY1NoPPa2AoKVELwptCh24/nsZeVXtVVt9tv9Hk4QzRYQeQInCulcxRvK+wJZlz/r8KUVEqPZ5Hd7Ipb9kHHvaIG0BfL1F7/x6wpeTJc6dWLQ7rdvW/8Bl0w1FNHLGl0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/1c6636d6ee225d292416ecb6b20727ec/3cea3/6.webp 1000w", "/static/1c6636d6ee225d292416ecb6b20727ec/34ef4/6.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/1c6636d6ee225d292416ecb6b20727ec/e11df/6.png 1000w", "/static/1c6636d6ee225d292416ecb6b20727ec/a987b/6.png 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1c6636d6ee225d292416ecb6b20727ec/a987b/6.png",
                "alt": "Richard interviews friend Rob de Vries",
                "title": "Richard interviews friend Rob de Vries",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      